import { useEffect, useState } from "react";
import { FaSchool } from "react-icons/fa";
import { GiTeacher } from "react-icons/gi";
import { PiStudentBold } from "react-icons/pi";
export default function Count() {
    let[man,setMan] = useState([])
    const apiUrl = process.env.REACT_APP_API_URL;
    useEffect(() => {
       
        fetchItem()
    }, []);
    const fetchItem = async () => {
        try {
            const response = await fetch(`${apiUrl}personnelcount/setcount/`);
            if (!response.ok) {
                throw new Error('Failed to fetch image names');
            }
            const data = await response.json();
            console.log(data);
            setMan(data)

        } catch (error) {
            console.error('Error fetching image names:', error);
        }
    };
    return <div className="px-2">
        <div className="w-full  lg:w-3/4 mx-auto bg-gray-500 h-full lg:my-8 rounded-lg p-2">
            <div className="bg-white p-2 m-2">
            <main className="grid grid-cols-3">
                <div className="flex flex-col items-center" >
                    <PiStudentBold className="text-red-600 text-4xl sm:text-6xl lg:text-8xl " />
                    <p className="text-center">จำนวนนักเรียน</p>
                    
                </div> 
                <div className="flex flex-col items-center">
                    <GiTeacher className="text-red-600 text-4xl sm:text-6xl lg:text-8xl" />
                    <p className="text-center">จำนวนคุณครู</p>
                   
                </div>
                <div className="flex flex-col items-center">
                    <FaSchool className="text-red-600 text-4xl sm:text-6xl lg:text-8xl" />
                    <p className="text-center">จำนวนห้องเรียน</p>
                   
                </div>
                
            </main>
            <div className="">
                    {man.map(item=>(
                        <div className="grid grid-cols-3 justify-items-center" key={item.id}>
                            <p>{item.studentcount}</p>
                            <p>{item.teachercount}</p>
                            <p>{item.classroomcount}</p>
                        </div>
                    ))}
                </div>
        </div>
            </div>
            
    </div>
}