import React, { useState, useEffect } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';


export default function SlideImg() {
  const [imageNames, setImageNames] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const fetchImageNames = async () => {
    try {
      const response = await fetch(`${apiUrl}imageslide/getimg-slide/`);

      if (!response.ok) {
        throw new Error('Failed to fetch image names');
      }
      const names = await response.json();
      setImageNames(names);
    } catch (error) {
      console.error('Error fetching image names:', error);
    }
  };

  useEffect(() => {
    fetchImageNames();
  
  }, []);
  
  return (
    <div data-aos="fade-up" className='' >
      <div className=''>
        <Splide options={{ type: 'loop', autoplay: true, interval: 2000 }}>
          {imageNames.map((item, index) => (
            <SplideSlide key={index} className="h-[350px] w-full sm:h-[450px] lg:h-[700px]">
              <img className='w-full h-full object-cover' src={item.image} alt={`Slide ${index}`} />
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </div>
  );
}
