import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Nav from './compornent/Nav';
import SlideImg from './compornent/SlideImg.jsx';
import Post from './compornent/Post.jsx';
import ActivityImg from './compornent/ActivityImg.jsx';
import Count from './compornent/Count.jsx';
import SoloImg from './compornent/SoloImg.jsx';
import Footer from './compornent/Footer.jsx';
import { Helmet } from "react-helmet";
// import MessengerCustomerChat from 'react-messenger-customer-chat';
function App() {
  useEffect(() => {
    AOS.init({
      // Global settings
      offset: 120,
      delay: 100,
      duration: 400,
      easing: 'ease',
      once: false,
      mirror: false,
      anchorPlacement: 'top-bottom',
    });
  }, []);
  AOS.init()
  return (
    <>

      <Helmet>
        <meta charSet="utf-8" />
        <title>โรงเรียนบ้านท่าบ่อ โรงเรียนขยายโอกาส ที่ หมู่ 2 ตำบลแจระแม อำเภอเมือง จังหวัดอุบลราชธานี</title>

      </Helmet>
      <div className='font-thai flex flex-col min-h-screen' >
        
        <Nav />
        <div data-aos="fade-down"><SlideImg /></div>

        <div data-aos="fade-down" className='mt-28'>
          <Post />
        </div>
        <div data-aos="fade-right" className='my-28'>
          <ActivityImg />
        </div>
        <div data-aos="zoom-in-up">
          <Count />
        </div>
        <div data-aos="fade-up">
          <SoloImg />
        </div>
        {/* <MessengerCustomerChat
          pageId="100057064105468"
          appId="495279939819580"
       
        />, */}
        <div className='mt-auto'>
          <Footer />
          
        </div>

       
      </div>

    </>
  );
}

export default App;
