
import { FaDove } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Nav from '../compornent/Nav';
import Footer from '../compornent/Footer';
import { useEffect, useState } from 'react';
import {Helmet} from "react-helmet";

const apiUrl = process.env.REACT_APP_API_URL;

const GalleryPage = () => {
    const [albums, setAlbums] = useState([]);
    console.log(albums)

    useEffect(() => {
        fetchAlbums();
    }, []);

    const fetchAlbums = async () => {
        try {
            const response = await fetch(`${apiUrl}photoalbums/albums/`);
            if (!response.ok) {
                throw new Error('Failed to fetch albums');
            }
            const data = await response.json();
            setAlbums(data);
        } catch (error) {
            console.error('Error fetching albums:', error);
        }
    };

    const onInit = () => {
        console.log('LightGallery initialized');
    };

    return (
        <div className="font-thai">
            <Helmet>
                <meta charSet="utf-8" />
                <title>รูปภาพกิจกรรมทั้งหมด</title>
             
                </Helmet>
            <Nav/>
            <div className="lg:w-3/4 mx-auto mt-8 w-full  ">
            <h1 className='text-red-600 text-2xl my-4'>รูปภาพกิจกรรมทั้งหมด</h1>
                <div className='grid sm:grid-cols-2 lg:grid-cols-4 gap-4 justify-items-center p-3'>
                {albums.map(album=>(
                    <div key={album.id} className='bg-red-700 p-6 rounded-md ' >
                        
                    <Link to={`/GalleryPage/GalleryDetail/${album.id}`} >
                    <img src={album.cover_img} className='h-72 w-full object-cover' alt="" />
                    <p className='text-center my-4 text-white text-xl lg:text-2xl'>กิจกรรม :{album.title}</p>
                    
                    </Link>
                       
                    </div>
                    
                    
                ))}
                    
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default GalleryPage;

